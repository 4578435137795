<template>
  <div>
    <ul class="w1200">
      <li class="list_item flex_box" v-for="item in list" :key='item.id'>
        <p class="line"></p>
        <div class="flex1">
          <h3 class="title">{{item.partyTitle}}</h3>
          <div class="time">{{item.createTime}}</div>
          <p class="desc" v-html="item.subtitle"></p>
          <p class="detail" @click="goDetails(item)">
            查看详情 <span class="iconfont icon-youjiantou"></span>
          </p>
        </div>
        <el-image :src="baseUrlImg+'/image?fileName='+item.photo" fit='fill' class="img" style="width: 240px; height: 150px"></el-image>
      </li>
    </ul>
    <pagination ref='pagination' :total='total' @currentChange='currentChange'></pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      total:0,
      params:{
        pageNum:1,
        pageSize:10
      },
      list:[]
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList() {
      this.$api.getPartyBuildingByPage(this.params).then(res=>{
        this.list = res.rows
        this.total = res.total
      })
    },
    currentChange(val) {
      this.params.pageNum = val
      this.getList()
    },
    goDetails(item) {
      this.$router.push({
        path:'/partyBuildingDetails/'+item.id,
        query:{
          type:this.$route.query.type
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '~@/assets/stylus/variable'
.list_item
  padding 30px 0
  cursor pointer
  background: #f4f4f4
  margin-bottom 20px
  &:hover
    box-shadow 0 0 8px 4px rgba(0,0,0,.1)
    .title
      color $theme-color
  .img
    margin-left 36px
  .time
    font-size 16px
    color #999
  .line
    width 2px
    height 180px
    background: $theme-color
    margin-right 20px
  .title
    font-size 23px
    color #222
    margin 15px 0
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
  .desc
    font-size 17px
    color #666
    line-height: 30px
    margin 20px 0
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  .detail
    color $theme-color
    font-size 14px
    display flex
    align-item center
</style>